@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
}

.main {
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loader-container {
  background-color: #ffffff;
}

.container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 4rem;
}

.container img {
  width: 60dvw;
  max-width: 200px;
}

.subtitle {
  color: #222222;
  font-size: 20px;
  font-family: Roboto, sans-serif;
}

.loading-indicator {
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid #3d358f;
  border-radius: 50%;
  border-bottom-color: #6155e4;
  animation: rotate 1.5s infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
